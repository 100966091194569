<template>
  <v-app class="bg-login" id="auth">
    <v-main>
      <v-container class="login-container" fluid>
        <v-row align-content="center" class="login-container__row">
          <v-col
            class="login-container__col left hidden-sm-and-down"
            cols="12"
            md="6"
          >
            <div class="left__text">
              <p class="uppercase">{{ $t('login_knowledge') }}</p>
              <p class="uppercase">{{ $t('login_&_reliability') }}</p>
            </div>
          </v-col>
          <!-- FORM LOGIN -->
          <v-col class="login-container__col right" cols="12" md="6">
            <v-row>
              <v-col cols="8" offset="2">
                <div
                  class="logo-container d-flex flex-column justify-center align-center"
                >
                  <img
                    src="../../assets/AV_002_logo.jpg"
                    alt="Logo Av"
                    class="logo-container__image"
                  />
                  <p
                    class="logo-container__text"
                    v-if="this.correoSend === false"
                  >
                    {{ $t('login_forgot_user_message') }}
                  </p>
                </div>
              </v-col>
              <v-col cols="10" offset="1">
                <v-card elevation="0" v-if="this.correoSend === false">
                  <v-card-text>
                    <ValidationObserver ref="observer" v-slot="{ invalid }">
                      <form @submit.prevent="handleSubmit">
                        <v-row class="px-md-5">
                          <v-col cols="12">
                            <!-- EMAIL FIELD -->
                            <ValidationProvider
                              :name="$t('login_email_alternative')"
                              rules="required|email"
                              v-slot="{ errors }"
                            >
                              <v-text-field
                                :label="$t('login_email_alternative')"
                                v-model="user.email_alternative"
                                :hint="$t('login_email_alternative_hint')"
                                autofocus
                                :disabled="disabled == 1"
                                :error-messages="errors[0]"
                              />
                            </ValidationProvider>
                          </v-col>
                        </v-row>
                        <v-row class="mb-5">
                          <v-col cols="12">
                            <v-row justify="center">
                              <vue-recaptcha
                                v-if="recaptchaShow"
                                sitekey="Your key here"
                                :loadRecaptchaScript="true"
                              ></vue-recaptcha>
                            </v-row>
                          </v-col>
                        </v-row>
                        <v-row
                          class="d-flex flex-column-reverse flex-md-row justify-center align-center"
                          dense
                        >
                          <v-col
                            class="d-flex flex-row justify-center align-center"
                            cols="12"
                            sm="6"
                          >
                            <v-btn
                              class="btn-cancel"
                              outlined
                              color="primary"
                              to="/login"
                              rounded
                              >{{ $t('btn_cancel') }}</v-btn
                            >
                          </v-col>
                          <v-col
                            class="d-flex flex-row justify-center align-center"
                            cols="12"
                            sm="6"
                          >
                            <v-btn
                              color="primary"
                              type="submit"
                              class="login-button"
                              :loading="loading"
                              :disabled="invalid"
                              >{{ $t('btn_reestablish') }}</v-btn
                            >
                          </v-col>
                        </v-row>
                      </form>
                    </ValidationObserver>
                  </v-card-text>
                </v-card>
                <v-card rounded elevation="0" class="pa-6" v-else>
                  <v-card-text>
                    <v-row justify="center">
                      <span class="logo-container__text">
                        {{ message }}
                      </span>

                      <span class="logo-container__text_two mt-6">
                        {{ $t('login_in_case') }}
                        <a v-on:click="handleSubmit()" class="mx-1">
                          {{ $t('login_click_here') }}
                        </a>
                        {{ $t('login_send_again') }}
                      </span>
                    </v-row>
                    <v-row justify="center" class="mt-10">
                      <RouterLink :to="{ name: 'Login' }" class="btn-default">
                        <span justify="center">
                          {{ $t('login_init_session') }}
                        </span>
                      </RouterLink>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import UserService from '@/services/user'
import VueRecaptcha from 'vue-recaptcha'
import SettingsService from '@/services/setting'
import { mapState } from 'vuex'

export default {
  components: { VueRecaptcha },
  data() {
    return {
      disabled: 0,
      loading: false,
      correoSend: false,
      message: null,
      user: {
        email_alternative: ''
      },
      recaptchaShow: false
    }
  },
  computed: {
    ...mapState('language', ['languages', 'idiom']),
    imgCard() {
      // return require('@/assets/logo-safe.png')
      return ''
    }
  },
  async created() {
    await this.getSettings()
  },
  methods: {
    async handleSubmit() {
      this.loading = true
      this.correoSend = false
      this.disabled = 1
      try {
        alert
        var data = await UserService.sendEmailUserRecoveryConfirmation(
          this.user.email_alternative,
          this.idiom.code
        )
        this.correoSend = true
        this.message = data.message
      } catch (e) {
        this.$dialog.notify.error(e)
        this.user.email_alternative = ''
        this.correoSend = false
      } finally {
        this.loading = false
        this.disabled = 0
      }
    },
    async getSettings() {
      const { data } = await SettingsService.fetch({
        'filter[key]': 'recaptcha'
      })
      this.recaptchaShow = data.data[0].value
    }
  }
}
</script>
<style>
#auth label.v-label {
  transform: translateY(-18px) scale(.75);
}
</style>
<style scoped>
.theme--light.v-application.bg-login {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  z-index: 0;
  background: rgba(8, 48, 84, 1);
  background-size: cover !important;
  background-repeat: no-repeat;
  /* font-family: Lato; */
}
.login-container {
  min-height: 100vh;
}
.login-container__row {
  min-height: 100vh;
}
.login-container__col {
  min-height: 100vh;
}
.left {
  /* background-color: rgb(1, 1, 75); */
  /* background: url('../../assets/AV_001_inicio.png') no-repeat center/100% !important; */
  background-image: url('../../assets/AV_001_inicio_sinletras.jpg');
  /* background-position: center center; */
  /* background-repeat: no-repeat; */
  /* background-attachment: fixed; */
  background-size: cover;
  background-color: rgb(1, 1, 75);

  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  height: 100vh;
}
.left__text {
  /* width: 350px; */

  text-align: left;
  font: normal normal 300 44px/52px Lato !important;
  letter-spacing: -1.1px !important;
  color: #0294de !important;
  opacity: 1;
}
.right {
  display: flex;
  align-items: center;

  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}
.test {
  background-color: red;
}
.form {
  display: flex;
  flex-direction: column;
}
.links {
  display: flex;
  justify-content: space-between;
}
.links a {
  font: normal normal normal 16px/27px Lato;
  letter-spacing: 0px;
  color: #00559e;
  opacity: 1;
  text-decoration: none;
}
.login-button {
  min-width: 140px !important;
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  opacity: 1;
}
.btn-cancel {
  min-width: 140px !important;
}
.logo-container__text {
  margin-top: 20px;
  text-align: center;
  line-height: 1.5 !important;
  font: normal normal normal 18px/14px Lato;
  letter-spacing: 0px;
  color: #8990ad;
  opacity: 1;
}

.logo-container__text_two {
  text-align: center;
  line-height: 1.5 !important;
  font: normal normal normal 18px/14px Lato;
  letter-spacing: 0px;
  color: #8990ad;
  opacity: 1;
}

.logo-container__image {
  width: 90%;
}
.bg-login {
  font: normal normal normal 16px/27px Lato;
}
.form label {
  font: normal normal normal 12px/15px Lato;
  letter-spacing: 0px;
}

.btn-default {
  background: transparent linear-gradient(112deg, #00559e 0%, #0090df 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 6px 10px 14px #00000029;
  border-radius: 28px;
  padding: 10px;
  opacity: 1;
  text-decoration: none;
}

.btn-default > span {
  text-align: center;
  font: normal normal normal 16px/19px Lato;
  letter-spacing: -0.4px;
  padding: 18px;
  color: #ffffff;
  text-transform: uppercase;
  opacity: 1;
}

.uppercase {
  text-transform: uppercase;
}

@media (max-width: 979px) {
  .logo-container__text {
    font: normal normal normal 16px/18px Lato;
  }
  .logo-container__text_two {
    font: normal normal normal 16px/18px Lato;
  }
  .text-left {
    font: normal normal normal 12px/27px Lato;
  }
}
</style>
