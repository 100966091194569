import axios from '@/plugins/axios'
import BaseService from './base'

export default class UserService extends BaseService {
  static resource() {
    return 'api/v1/users'
  }
  static async sendEmailConfirmation(email, lang) {
    try {
      await axios.get('/sanctum/csrf-cookie')
      const { data } = await axios.post(
        `${this.baseURL()}/api/v1/forget-password`,
        {
          env: true,
          email: email,
          lang: lang
        }
      )
      return data
    } catch (e) {
      throw e.response.data.message
    }
  }

  static async sendEmailUserRecoveryConfirmation(email, lang) {
    try {
      await axios.get('/sanctum/csrf-cookie')
      const { data } = await axios.post(
        `${this.baseURL()}/api/v1/forget-username`,
        {
          env: true,
          email_alternative: email,
          lang: lang
        }
      )
      return data
    } catch (e) {
      throw e.response.data.message
    }
  }

  static async updatePassword(user) {
    try {
      await axios.get('/sanctum/csrf-cookie')
      const { data } = await axios.post(
        `${this.baseURL()}/api/v1/reset-password`,
        {
          env: true,
          token: user.token,
          email: user.email,
          password: user.password,
          password_confirmation: user.password_confirmation
        }
      )
      return data
    } catch (e) {
      throw e.response.data.message
    }
  }

  static async me() {
    const { data } = await axios.get(`${this.baseURL()}/api/v1/me`)
    return data
  }
}
